<template>
  <div class="content">
    <div class="function">
      <div class="function-title">报警管理</div>
      <div class="function-box">
        <div
          class="function-item"
          v-for="(item, index) in alarmList"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
          <p>{{ item.value }}</p>
        </div>
      </div>
      <div class="function-box">
        <div
          class="function-item"
          v-for="(item, index) in alarmList1"
          :key="index"
        >
          <img :src="item.url" alt="" />
          <div>{{ item.name }}</div>
          <p>{{ item.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      alarmList: [
        {
          url: require("../../../../assets/image/buildingImg/shebe.png"),
          name: "设备报警",
          value: "重要能耗设备的运行状态异常报警。",
        },
        {
          url: require("../../../../assets/image/buildingImg/huanjing.png"),
          name: "环境质量报警",
          value: "空气质量，温度，湿度等异常报警。",
        },
        {
          url: require("../../../../assets/image/buildingImg/dian.png"),
          name: "电源故障报警",
          value: "设备电源故障，ups断电报警。",
        },
        {
          url: require("../../../../assets/image/buildingImg/wiFi.png"),
          name: "网络故障报警",
          value: "设备通讯及网络故障等异常报警。",
        },
      ],
      alarmList1: [
        {
          url: require("../../../../assets/image/buildingImg/gaojin.png"),
          name: "报警级别设定",
          value: "基于事件的报警，报警分组管理，报警优先级管理。",
        },
      ],
    };
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 338px);
  margin-bottom: 50px;
  .function {
    padding: 0 10%;
    .function-title {
      text-align: center;
      padding: 30px 0;
      font-size: 40px;
      font-weight: 600;
      color: #333333;
    }
    .function-box {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 20px;
      .function-item {
        width: 23.5%;
        height: 27vh;
        // margin-right: 2%;
        background-color: #fff;
        img {
          width: 8vh;
          height: 8vh;
          margin: 30px 0 0 0;
        }
        div {
          font-size: 20px;
          font-weight: 600;
          margin: 20px 0;
          color: #333333;
        }
        p {
          width: 80%;
          margin: auto;
          font-size: 14px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
  }
}
</style>